import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';

const HorizontalProductsView = ({ category }) => {
    const [products, setProducts] = useState([]);
    const location = useLocation();  // Hook to detect URL changes

    // Fetch products from Firestore and shuffle them each time the component renders
    const fetchProducts = async () => {
        try {
            const q = query(collection(getFirestore(), 'products'), where('category', '==', category));
            const querySnapshot = await getDocs(q);

            // Get all products
            const productsArr = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            // Shuffle the products array
            const shuffledProducts = shuffleArray(productsArr);

            // Limit to the first 20 shuffled products
            const randomProducts = shuffledProducts.slice(0, 20);

            setProducts(randomProducts);
        } catch (error) {
            console.error("Error fetching products: ", error);
        }
    };

    // Shuffle the array using the Fisher-Yates algorithm
    const shuffleArray = (array) => {
        let shuffledArray = array.slice();
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    // Run the fetchProducts function when the component mounts or category changes or URL changes
    useEffect(() => {
        fetchProducts();
    }, [category, location.pathname]); // Dependency on category and location.pathname so it refetches on URL changes

    return (
        <div className="bg-white p-2 mt-4">
            <p className="text-black text-sm font-semibold mt-3 ml-4">Similar Decors</p>
            <div className="overflow-x-scroll flex space-x-4 p-2">
                {products.map((product, index) => (
                    <Link
                        to={`/product/${product.id}`}
                        key={index}
                        className="flex-shrink-0 w-48"
                    >
                        <img
                            src={product.downloadUrls[0]} // Assuming you have downloadUrls array in product data
                            alt={product.name}
                            className="w-48 h-48 object-cover rounded-md"
                        />
                        <p className="text-xs mt-2 truncate text-center">{product.name}</p>
                        <div className="px-2 flex">
                        <p className="text-xs pr-1 font-semibold">
                            ₹{Number(product.price).toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </p>
                            <p className="text-xs pr-1 text-gray-400 line-through">
                            ₹{Number(product.originalPrice).toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </p>
                            <p className="text-xs text-orange-400 font-semibold">
                                ({(((product.originalPrice - product.price) / product.originalPrice) * 100).toFixed(2)}%)
                            </p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default HorizontalProductsView;
