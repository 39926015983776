import React, { useState, useEffect } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import { getFirestore, collection, query, getDocs, where } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const AdminLeads = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage] = useState(10);
  const [leads, setLeads] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const db = getFirestore();

  // Set initial selected date to today's date in YYYY-MM-DD format
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Get "YYYY-MM-DD"
    setSelectedDate(formattedDate);
  }, []);

  useEffect(() => {
    const checkAdminStatus = (user) => {
      if (user && user.email === 'manuvini29@gmail.com') {
        setLoggedIn(true);
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        if (!selectedDate) {
          setLoading(false);
          return;
        }

        const datePath = formatDatePath(selectedDate);
        const leadsCollection = collection(db, "enquireDetails", datePath, "enquire");
        const querySnapshot = await getDocs(leadsCollection);

        const leadsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLeads(leadsArray);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching leads:', error);
        setLoading(false);
      }
    };

    fetchLeads();
  }, [selectedDate, db]);

  const formatDatePath = (date) => {
    const [year, month, day] = date.split("-");
    return `${year}_${month}_${day}`;
  };

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = leads.slice(indexOfFirstLead, indexOfLastLead);

  const totalPages = Math.ceil(leads.length / leadsPerPage);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    setCurrentPage(1);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  return (
    <div className="flex h-screen">
      <SidePanel />
      <div className="flex-1 ml-56">
        <AdminHeader />
        <div className="p-6">
          <div className="flex justify-between mb-4">
            <h2 className="text-2xl font-bold">Enquire</h2>
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              className="px-3 py-2 border rounded"
            />
          </div>
          <p className="mb-4">Total Leads: {leads.length}</p>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Name</th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Phone</th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Event Date</th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Decoration Name</th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {currentLeads.map((lead) => (
                  <tr key={lead.id}>
                    <td className="px-6 py-3 border">{lead.name}</td>
                    <td className="px-6 py-3 border">{lead.phone}</td>
                    <td className="px-6 py-3 border">{lead.eventDate}</td>
                    <td className="px-6 py-3 border">{lead.decorationName}</td>
                    <td className="px-6 py-3 border">
                      {new Date(lead.timestamp.toDate()).toLocaleString()}
                    </td>
                  </tr>
                ))}
                {currentLeads.length === 0 && (
                  <tr>
                    <td colSpan="5" className="px-6 py-3 border text-center">
                      No leads found for the selected date.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {totalPages > 1 && (
            <ul className="flex mt-4">
              {Array.from({ length: totalPages }).map((_, index) => (
                <li
                  key={index}
                  className={`px-3 py-1 border ${
                    currentPage === index + 1 ? "bg-gray-200" : "hover:bg-gray-200"
                  }`}
                  onClick={() => handlePageClick(index + 1)}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLeads;
