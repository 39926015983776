import React, { useState } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function AddCategory({ showModal, setShowModal }) {
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const db = getFirestore();
  const storage = getStorage();

  // Function to format the category name into a URL-friendly format
  const formatCategoryName = (name) => {
    return name
      .trim() // Remove leading/trailing whitespace
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with dashes
      .replace(/[^\w-]/g, ""); // Remove any non-alphanumeric characters (except dashes)
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleUpload = () => {
    if (image) {
      // Create a reference to the storage location for the image
      const storageRef = ref(storage, `category_images/${image.name}`);
      
      // Create a task to upload the image to Firebase Storage
      const uploadTask = uploadBytesResumable(storageRef, image);
      
      // Listen for state changes, errors, and completion of the upload
      uploadTask.on("state_changed",
        (snapshot) => {
          // Update the upload progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          // Handle upload error
          console.error("Error uploading image:", error);
          setError(error.message);
          setSuccess(false);
          setUploadProgress(0);
          setTimeout(() => {
            setError(null);
          }, 3000);
        },
        () => {
          // Image upload is complete, get the download URL
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              // Format category name to URL-friendly format
              const formattedCategory = formatCategoryName(category);
              
              // Add category data with image URL and formatted name to Firestore
              addDoc(collection(db, "categories"), {
                name: category,
                imageUrl: downloadURL,
                urlName: formattedCategory, // Add URL-friendly name
              })
              .then(() => {
                console.log("Category added successfully!");
                setSuccess(true);
                setUploadProgress(0);
                setTimeout(() => {
                  setSuccess(false);
                }, 3000);
                setError(null);
                setCategory("");
                setImage(null);
              })
              .catch((error) => {
                console.error("Error adding category: ", error);
                setSuccess(false);
                setError(error.message);
                setUploadProgress(0);
                setTimeout(() => {
                  setError(null);
                }, 3000);
              });
            })
            .catch((error) => {
              console.error("Error getting download URL:", error);
              setError(error.message);
              setSuccess(false);
              setUploadProgress(0);
              setTimeout(() => {
                setError(null);
              }, 3000);
            });
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Call handleUpload function to upload the image and add the category to Firestore
    handleUpload();
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full w-full flex items-center justify-center ${
        showModal ? "" : "hidden"
      }`}
    >
      <div className="bg-gray-100 w-1/2 shadow-lg rounded-lg">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-2">Add Category</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="category"
                className="block text-gray-700 font-bold mb-2"
              >
                Category Name
              </label>
              <input
                type="text"
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="border rounded-md px-4 py-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="image"
                className="block text-gray-700 font-bold mb-2"
              >
                Category Image
              </label>
              {image ? (
                <p>Image selected: {image.name}</p>
              ) : (
                <div className="relative m-2">
                  <label
                    htmlFor="image"
                    className="h-24 w-24 rounded-md border-dashed border-2 border-gray-400 flex items-center justify-center cursor-pointer"
                  >
                    <input
                      type="file"
                      id="image"
                      onChange={handleImageChange}
                      className="sr-only"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 8a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1V9a1 1 0 011-1h1V6a2 2 0 012-2h4a2 2 0 012 2v1h1zm-7-2a1 1 0 00-1 1v1h2V7a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </label>
                </div>
              )}
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 mr-2"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
              >
                Add
              </button>
            </div>
          </form>
        </div>
        {success && (
          <div className="bg-green-200 text-green-700 p-2 rounded-md my-2">
            Category added successfully!
          </div>
        )}
        {error && (
          <div className="bg-red-200 text-red-700 p-2 rounded-md my-2">
            Error: {error}
          </div>
        )}
        {uploadProgress > 0 && (
          <div className="mt-2">
            <progress className="w-full h-2" value={uploadProgress} max="100" />
          </div>
        )}
      </div>
    </div>
  );
}

export default AddCategory;
