import React, { useState, useEffect } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as XLSX from "xlsx";

const MassUploadPage = () => {
  const [excelData, setExcelData] = useState([]);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const db = getFirestore();

  useEffect(() => {
    const checkAdminStatus = (user) => {
      if (user && user.email === 'manuvini29@gmail.com') {
        setLoggedIn(true);
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  // Handle file input
  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setError(null);
      setSuccessMessage("");
    }
  };

  // Parse the Excel file
  const handleParseExcel = () => {
    if (!file) {
      setError("Please select an Excel file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const products = XLSX.utils.sheet_to_json(sheet);
      setExcelData(products);
    };
    reader.readAsBinaryString(file);
  };

  // Mass upload products to Firestore
  const handleMassUpload = async () => {
    try {
      for (const product of excelData) {
        const { name, price, originalPrice, category, description, rating, downloadUrls1 } = product;
        const formattedName = name.replace(/\s+/g, '-');
        const productData = {
          name,
          price,
          originalPrice,
          category,
          description: [description],
          rating,
          downloadUrls: [downloadUrls1],
        };

        await setDoc(doc(db, "products", formattedName), productData);
      }

      setSuccessMessage("Products uploaded successfully!");
    } catch (error) {
      setError("Error uploading products: " + error.message);
    }
  };

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  return (
    <div className="flex h-screen">
      <SidePanel />
      <div className="flex-1 ml-56">
        <AdminHeader />
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Mass Product Upload</h2>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="fileInput">
              Upload Excel File
            </label>
            <input
              type="file"
              id="fileInput"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              className="border rounded-md px-4 py-2 w-full"
            />
          </div>

          <button
            onClick={handleParseExcel}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 mb-4"
          >
            Parse Excel
          </button>

          {error && <div className="text-red-500">{error}</div>}
          {successMessage && <div className="text-green-500">{successMessage}</div>}

          {excelData.length > 0 && (
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse table-auto">
                <thead>
                  <tr>
                    <th className="border px-4 py-2">Name</th>
                    <th className="border px-4 py-2">Price</th>
                    <th className="border px-4 py-2">Original Price</th>
                    <th className="border px-4 py-2">Category</th>
                    <th className="border px-4 py-2">Description</th>
                    <th className="border px-4 py-2">Rating</th>
                    <th className="border px-4 py-2">Image URLs</th>
                  </tr>
                </thead>
                <tbody>
                  {excelData.map((product, index) => (
                    <tr key={index}>
                      <td className="border px-4 py-2">{product.name}</td>
                      <td className="border px-4 py-2">{product.price}</td>
                      <td className="border px-4 py-2">{product.originalPrice}</td>
                      <td className="border px-4 py-2">{product.category}</td>
                      <td className="border px-4 py-2">{product.description}</td>
                      <td className="border px-4 py-2">{product.rating}</td>
                      <td className="border px-4 py-2">{product.downloadUrls1}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {excelData.length > 0 && (
            <button
              onClick={handleMassUpload}
              className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              Mass Upload Products
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MassUploadPage;
