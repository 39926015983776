import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import axios from "axios";
import './App.css';
import Header from './Components/Header';
import CategoryScroll from './Components/CatComp';
import GridComponent from './Components/Grid';
import FloatingContact from './Components/FloatingContact';
import BalloonDecorationPage from './Components/BalloonDecorationPage';
import UserDetailsModal from './Components/UserDetailsModal';

function App() {
  const [userIP, setUserIP] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalCheckCompleted, setModalCheckCompleted] = useState(false);

  const db = getFirestore();




  const fetchUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setUserIP(response.data.ip);
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  };

  const checkIfModalShouldShow = async (ip) => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const datePath = `${year}_${month}_${day}`;

    const dateDocRef = doc(db, "userDetails", datePath);
    const ipDocRef = doc(dateDocRef, 'details', ip);

    const docSnap = await getDoc(ipDocRef);

    const dataP = docSnap.data();
    console.log(dataP);

    if (!dataP) {
      // Wait for 15 seconds before showing the modal
      setTimeout(() => {
        setShowModal(true);
      }, 10000);
    }

    setModalCheckCompleted(true); // Mark modal check as complete
  };





  useEffect(() => {


    // Fetch user IP on component mount
    fetchUserIP();
  }, []);

  useEffect(() => {
    if (userIP && !modalCheckCompleted) {
      checkIfModalShouldShow(userIP);
    }
  }, [userIP, modalCheckCompleted]);

  return (
    <>
      <Header />
      <CategoryScroll />
      <GridComponent category={"all"} />
      {/* {showModal && <UserDetailsModal userIP={userIP} setShowModal={setShowModal} />} */}
      <FloatingContact />
       {/* <BalloonDecorationPage />  */}
    </>
  );
}

export default App;
