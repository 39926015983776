import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Components/Header";
import GridComponent from "./Components/Grid";
import FilterSort from "./Components/FilterSort";
import UserDetailsModal from "./Components/UserDetailsModal";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import axios from "axios";
import { trackActivity } from "./Components/TrackUser";

const ProductList = () => {
  const { list } = useParams();
  const [category, setCategory] = useState(list);
  const [filterCriteria, setFilterCriteria] = useState('');
  const [userIP, setUserIP] = useState('');
  const [showModal, setShowModal] = useState(false);

  const db = getFirestore();

  // Function to get user's IP address
  const fetchUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setUserIP(response.data.ip);
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  };

  // Check if the modal should be shown based on the IP and date
  
  const checkIfModalShouldShow = async (ip) => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const datePath = `${year}_${month}_${day}`;

    const dateDocRef = doc(db, "userDetails", datePath);
    const ipDocRef = doc(dateDocRef, 'details', ip);

    const docSnap = await getDoc(ipDocRef);

    const dataP = docSnap.data();
    console.log(dataP);

    if (!dataP) {
      setShowModal(true);
      
    }
  }; 

  useEffect(() => {

    window.scrollTo(0, 0);


    fetchUserIP().then(() => {
      if (userIP) {
        
        checkIfModalShouldShow(userIP);
      }
    });
  }, [userIP]); 

  const handleFilter = (criteria) => {
    setFilterCriteria(criteria);
  };

  return (
    <>
      <Header back={true} />
      <GridComponent category={category} filterCriteria={filterCriteria} />
      {showModal &&  <UserDetailsModal userIP={userIP} setShowModal={setShowModal} />}
     
     
      <FilterSort onFilter={handleFilter} />
    </>
  );
};

export default ProductList;
