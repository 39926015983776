import { getFirestore, collection, addDoc, doc } from "firebase/firestore";
import axios from "axios";

let userIP = null;

// Function to get the user's IP
const getUserIP = async () => {
  if (!userIP) {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      userIP = response.data.ip;
    } catch (error) {
      console.error("Failed to fetch user IP:", error);
      userIP = "unknown"; // Fallback IP
    }
  }
  return userIP;
};

// Function to get the current date in the format year_month_day
const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(now.getDate()).padStart(2, "0");
  return `${year}_${month}_${day}`;
};

// Track user activity
export const trackActivity = async (pageName, action) => {
  const db = getFirestore();
  const userIP = await getUserIP();
  const datePath = getCurrentDate();

  // Reference the subcollection under "trackActivity/{date}/activities"
  const dateDocRef = doc(db, "trackActivity", datePath);
  const activitiesCollection = collection(dateDocRef, "activities");

  // Add a new document with user activity
  const data = {
    ip: userIP,
    page: pageName,
    action: action,
    timestamp: new Date(),
    referrer: document.referrer || "Direct",
  };

  await addDoc(activitiesCollection, data);
};
