import React, { useState, useEffect } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import { getFirestore, collection, getDocs, query, where, doc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AdminUserActivity = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesPerPage] = useState(10);
  const [ips, setIPs] = useState([]);
  const [activities, setActivities] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expandedIPs, setExpandedIPs] = useState({}); // Track expanded rows

  const db = getFirestore();

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setSelectedDate(formattedDate);
  }, []);

  useEffect(() => {
    const checkAdminStatus = (user) => {
      if (user && user.email === "manuvini29@gmail.com") {
        setLoggedIn(true);
      } else {
        window.location.href = "/admin";
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchIPs = async () => {
      try {
        if (!selectedDate) {
          setLoading(false);
          return;
        }

        const datePath = formatDatePath(selectedDate);
        const dateDocRef = doc(db, "trackActivity", datePath);
        const activitiesCollection = collection(dateDocRef, "activities");
        const querySnapshot = await getDocs(activitiesCollection);

        const ipSet = new Set();
        querySnapshot.docs.forEach((doc) => {
          const activity = doc.data();
          if (activity.ip) ipSet.add(activity.ip);
        });

        setIPs(Array.from(ipSet));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching IPs:", error);
        setLoading(false);
      }
    };

    fetchIPs();
  }, [selectedDate, db]);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        if (!selectedDate) {
          setActivities([]);
          return;
        }
  
        const datePath = formatDatePath(selectedDate);
        const dateDocRef = doc(db, "trackActivity", datePath);
        const activitiesCollection = collection(dateDocRef, "activities");
        const querySnapshot = await getDocs(activitiesCollection);
  
        const activitiesArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        // Sort activities by timestamp (ascending)
        activitiesArray.sort((a, b) => {
          return a.timestamp.toDate() - b.timestamp.toDate();
        });
  
        setActivities(activitiesArray);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };
  
    fetchActivities();
  }, [selectedDate, db]);

  const formatDatePath = (date) => {
    const [year, month, day] = date.split("-");
    return `${year}_${month}_${day}`;
  };

  const toggleExpandIP = (ip) => {
    setExpandedIPs((prevState) => ({
      ...prevState,
      [ip]: !prevState[ip],
    }));
  };

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  return (
    <div className="flex h-screen">
      <SidePanel />
      <div className="flex-1 ml-56">
        <AdminHeader />
        <div className="p-6">
          <div className="flex justify-between mb-4">
            <div>
              <h2 className="text-2xl font-bold">User Activity</h2>
              <p className="text-gray-500">Select a date to view activity logs.</p>
            </div>
            <div>
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                className="px-3 py-2 border rounded"
              />
            </div>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <p className="mb-4">Total IPs: {ips.length}</p>
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      IP
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ips.map((ip) => (
                    <React.Fragment key={ip}>
                      <tr>
                        <td className="px-6 py-3 border">{ip}</td>
                        <td className="px-6 py-3 border">
                          <button
                            className="text-blue-500 hover:underline"
                            onClick={() => toggleExpandIP(ip)}
                          >
                            {expandedIPs[ip] ? "Hide Activities" : "View Activities"}
                          </button>
                        </td>
                      </tr>
                      {expandedIPs[ip] && (
                        <tr>
                          <td colSpan="2" className="px-6 py-3 border bg-gray-100">
                            <table className="w-full border-collapse">
                              <thead>
                                <tr>
                                  <th className="px-4 py-2 bg-gray-300 text-gray-700 border">
                                    Page
                                  </th>
                                  <th className="px-4 py-2 bg-gray-300 text-gray-700 border">
                                    Action
                                  </th>
                                  <th className="px-4 py-2 bg-gray-300 text-gray-700 border">
                                    Referrer
                                  </th>
                                  <th className="px-4 py-2 bg-gray-300 text-gray-700 border">
                                    Timestamp
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {activities
                                  .filter((activity) => activity.ip === ip)
                                  .map((activity) => (
                                    <tr key={activity.id}>
                                      <td className="px-4 py-2 border">{activity.page}</td>
                                      <td className="px-4 py-2 border">{activity.action}</td>
                                      <td className="px-4 py-2 border">{activity.referrer}</td>

                                      <td className="px-4 py-2 border">
                                        {new Date(
                                          activity.timestamp.toDate()
                                        ).toLocaleString()}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                  {ips.length === 0 && (
                    <tr>
                      <td
                        colSpan="2"
                        className="px-6 py-3 border text-center"
                      >
                        No IPs found for the selected date.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminUserActivity;
