import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const CategoryScroll = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const db = getFirestore();
    const fetchCategories = async () => {
      try {
        const q = query(collection(db, 'categories'));
        const querySnapshot = await getDocs(q);
        const categoriesData = [];
        querySnapshot.forEach((doc) => {
          const { name, imageUrl, urlName } = doc.data();
          categoriesData.push({ id: doc.id, name, urlName,imageUrl });
        });
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  if (categories.length === 0) {
    // Placeholder content or loading indicator
    const placeholderCategories = Array.from({ length: 5 }, (_, index) => index + 1);

    return (
      <div className="overflow-x-scroll bg-white whitespace-nowrap">
        {placeholderCategories.map((index) => (
          <div key={index} className="inline-block mr-4 bg-gray-200 animate-pulse">
            <div className="w-24 h-24 bg-gray-300 mb-1"></div>
            <div className="w-24 h-4 bg-gray-300"></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="overflow-x-scroll bg-white p-4 whitespace-nowrap">
      {categories.map((category, index) => (
        <Link to={`/DecorationList/Bangalore/${category.urlName}`} key={index}>
          <div className="inline-block mr-4">
            <img
              src={category.imageUrl}
              alt={category.name}
              style={{ width: '62px', height: '60px' }}
            />
            <p className="text-center text-xs">{category.name}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default CategoryScroll;
