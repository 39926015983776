import React, { useState } from "react";
import { getFirestore, doc, setDoc, addDoc, collection } from "firebase/firestore";

const EnquireNowModal = ({ setShowModal, decorationName }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [eventDate, setEventDate] = useState("");

  const db = getFirestore();


  const closeModal = async () => {


    setShowModal(false);
  };

  // Get current date for Firestore
  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}_${month}_${day}`;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const now = new Date();
    const datePath = getCurrentDate();

    // Reference to the userDetails collection, with the date as a document
    const dateDocRef = collection(db, "enquireDetails", datePath, 'enquire');

    // Reference to the IP document within the details subcollection

    // Set the user details in the IP document, including the decoration name
    const data = {
      name,
      phone,
      eventDate,
      decorationName, // New field for decoration name
      timestamp: now,
    }

    addDoc(dateDocRef,data);

    setShowModal(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80 relative">
      <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>  
        <h2 className="text-lg font-bold text-center mb-4">
          🎉 Enquire Now For Your Event!
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Name</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Phone Number</label>
            <input
              type="tel"
              className="w-full px-3 py-2 border rounded"
              placeholder="Enter your phone number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Event Date</label>
            <input
              type="date"
              className="w-full px-3 py-2 border rounded"
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-green-600 text-white py-2 rounded"
          >
            Submit 
          </button>
        </form>
        <p className="mt-3 text-xs text-center text-gray-500">
          You will receive the details on your WhatsApp.
        </p>
      </div>
    </div>
  );
};

export default EnquireNowModal;
